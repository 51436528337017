import React, { useEffect } from "react";
import { ReactComponent as KnightImage } from "./images/knight.svg";
import gsap from "gsap";
export default function Knight() {
  useEffect(() => {
    // shoulder Animation
    let shoulderAnimation = gsap.timeline({ repeat: -1 });
    shoulderAnimation.to(["#shoulders", "#arms"], {
      duration: 1,
      ease: "Power3.easeOut",
      translateY: "-0.5px",
    });
    shoulderAnimation.to(["#shoulders", "#arms"], {
      duration: 1,
      ease: "Power3.easeOut",
      translateY: "0.5px",
    });
    shoulderAnimation.yoyo();
  }, []);
  return <KnightImage className=" w-full h-full lg:h-1/2 lg:w-1/2" />;
}
