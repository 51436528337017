import React, { useState, Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import ReactFullpage from "@fullpage/react-fullpage";

import Content from "./Content";
export default function App() {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  return (
    <div className="max-w-screen overflow-x-hidden">
      <Content />
      {/* {isMobile ? (
        <Content />
      ) : (
        <ReactFullpage
          licenseKey={"8B7C030B-08524C75-98A40180-96E13ACF"}
          scrollingSpeed={1000}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <Content />
              </ReactFullpage.Wrapper>
            );
          }}
        />
      )} */}
    </div>
  );
}
