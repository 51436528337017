import React, { useEffect } from "react";
import { ReactComponent as LaptopImage } from "./images/laptop.svg";
import gsap from "gsap";

export default function Laptop() {
  useEffect(() => {
    // code lines animation
    const codeLines = [
      "#code-line1",
      "#code-line2",
      "#code-line3",
      "#code-line4",
      "#code-line5",
      "#code-line6",
      "#code-line7",
      "#code-line8",
      "#code-line9",
    ];
    const codeLinesReversed = [
      "#code-line9",
      "#code-line8",
      "#code-line7",
      "#code-line6",
      "#code-line5",
      "#code-line4",
      "#code-line3",
      "#code-line2",
      "#code-line1",
    ];
    let linesAnimation = gsap.timeline({ repeat: -1 });
    // pen animation
    linesAnimation
      .from(codeLines, {
        duration: 0.3,
        scaleX: 0,
        stagger: 0.3,
        ease: "power1.out",
      })
      .to(codeLinesReversed, {
        duration: 0.3,
        scaleX: 0,
        stagger: 0.3,
        ease: "power1.out",
      });
    let penAnimation = gsap.timeline({ repeat: -1 });
    // first
    penAnimation.to("#pen", {
      duration: 0.7,
      translateY: "-5em",
      translateX: "-2em",
    });
    // second
    penAnimation.to("#pen", {
      duration: 0.7,
      translateX: "-7.5em",
    });
    // third
    penAnimation.to("#pen", {
      duration: 0.7,
      translateY: "1.5em",
      translateX: "-9em",
    });
    // fourth
    penAnimation.to("#pen", {
      duration: 0.7,
      translateX: "0.1em",
    });
    // fifth
    penAnimation.to("#pen", {
      duration: 0.7,
      translateY: "-0.99em",
    });
    // laptop light animation
    let laptopAnimation = gsap.timeline({ repeat: -1 });
    laptopAnimation.to("#laptop-light", {
      duration: 1,
      opacity: 0,
    });
    laptopAnimation.yoyo(true);
  }, []);
  return (
    <LaptopImage className="lg:h-1/3 lg:w-1/3 lg:mx-10 self-end  w-full h-full  " />
  );
}
